import React from 'react';

import { Tag, TagLabel } from '@chakra-ui/react';

const NewTag = (props) => {
  return (
    <Tag
      variant="status"
      colorScheme="green"
      mx={0}
      alignSelf="center"
      verticalAlign="text-top"
      {...props}
    >
      <TagLabel>New</TagLabel>
    </Tag>
  );
};

export default NewTag;
