import {
  AsyncCreatableDropdown,
  AsyncDropdown,
  AsyncPaginateDropdown,
  CreatableDropdown,
  Dropdown as DSDropdown,
} from '@firehydrant/design-system';

import firehydrantAPI from '../../../../helpers/firehydrantAPI';
import { labelFormat } from '../../../../helpers/forms';
import internalAPI from '../../../../helpers/internalAPI';
import { convertToOptions } from '../helpers';

export const loadAsyncOptions = (
  apiPath,
  labelFormatType,
  convertData = true,
  customRequestParam = 'query',
  groupOptions = null,
  internal = false,
  customOptionConversion = null,
) => {
  return async (loadedOptions) => {
    const apiCall = internal ? internalAPI : firehydrantAPI;
    const {
      data: { data: results },
    } = await apiCall(
      apiPath,
      loadedOptions ? { params: { [customRequestParam]: loadedOptions } } : {},
    );

    if (customOptionConversion) {
      return customOptionConversion(results);
    }

    if (labelFormatType) {
      return results.map((value) => labelFormat(value, labelFormatType));
    }

    if (groupOptions) {
      return groupOptions(results);
    }

    return convertData ? convertToOptions(results) : results;
  };
};

export const loadAsyncPaginateOptions = (
  apiPath,
  customOptionConversion = null,
  customRequestParam = 'query',
  internal = false,
  labelFormatType = null,
) => {
  const apiCall = internal ? internalAPI : firehydrantAPI;
  return async (value, loadedOptions, params = { page: 1 }) => {
    if (!params.page) {
      params.page = 1;
    }
    const { page } = params;
    const {
      data: { data: results, pagination },
    } = await apiCall(
      apiPath,
      loadedOptions
        ? { params: { [customRequestParam]: value, ...params } }
        : {},
    );
    const hasMore = pagination.next && pagination.next > page;

    let options;
    if (labelFormatType) {
      options = results.map((value) => labelFormat(value, labelFormatType));
    } else if (customOptionConversion) {
      options = customOptionConversion(results, page);
    } else {
      options = convertToOptions(results);
    }
    return {
      options,
      hasMore,
      additional: {
        ...params,
        page: page + 1,
      },
    };
  };
};

export const loadNonPaginatedOptions = (
  apiPath,
  {
    perPage = 20,
    customOptionConversion = null,
    customRequestParam = 'query',
    labelFormatType = null,
    filterOptions = null,
    additionalPrependedOptions = false,
  } = {},
) => {
  return async (value, loadedOptions, params = { page: 1 }) => {
    const { page } = params;

    try {
      // Make API request with pagination parameters
      const { data } = await firehydrantAPI(
        apiPath,
        loadedOptions
          ? {
              params: {
                [customRequestParam]: value,
                page,
                per_page: perPage,
                ...params,
              },
            }
          : {},
      );

      const results = data.data;

      // Filter options if filter function provided
      let options = filterOptions ? filterOptions(results) : results;

      // Add any prepended options if specified
      options = additionalPrependedOptions
        ? [...additionalPrependedOptions, ...options]
        : options;

      // Format options based on provided configuration
      let formattedOptions;
      if (labelFormatType) {
        formattedOptions = options.map((value) =>
          labelFormat(value, labelFormatType),
        );
      } else if (customOptionConversion) {
        formattedOptions = customOptionConversion(options, page);
      } else {
        formattedOptions = convertToOptions(options);
      }

      // Determine if there are more pages based on the number of results
      const hasMore = options.length >= perPage;

      return {
        options: formattedOptions,
        hasMore,
        additional: hasMore
          ? {
              ...params,
              page: page + 1,
            }
          : undefined,
      };
    } catch (error) {
      // If we get a 400 error (page out of range), return empty results with no more pages
      if (error.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
          additional: undefined,
        };
      }
      // For other errors, rethrow to be handled by the global error handler
      throw error;
    }
  };
};

export const getDropdownComponent = (type) => {
  switch (type) {
    case 'async':
      return AsyncDropdown;
    case 'select':
      return DSDropdown;
    case 'creatable':
      return AsyncCreatableDropdown;
    case 'async-paginate':
      return AsyncPaginateDropdown;
    case 'sync-creatable':
      return CreatableDropdown;
    default:
      return DSDropdown;
  }
};
