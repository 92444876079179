import React, { useMemo } from 'react';

import {
  Box,
  Button,
  ButtonGroup as ChakraButtonGroup,
  Checkbox as ChakraCheckbox,
  Input as ChakraInput,
  Switch as ChakraSwitch,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import {
  IconButton,
  PlusIcon,
  TextArea,
  TextAreaFooterMarkdownLink,
} from '@firehydrant/design-system';
import {
  Field,
  Formik,
  FieldArray as FormikFieldArray,
  Form as FormikForm,
  useFormikContext,
} from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import DateTime from 'components/common/DateTime';
import TooltipWrapper from 'components/common/TooltipWrapper';

import Prompt from '../../Prompt';
import {
  getDropdownComponent,
  loadAsyncOptions,
  loadAsyncPaginateOptions,
} from './FormV2Helpers';

function Form({
  validationSchema,
  initialValues,
  onSubmit,
  enableReinitialize,
  children,
  maxWidth = '800px',
  buttonSet,
  gap = '3',
  innerRef,
  enablePrompt = true,
  ...props
}) {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
      innerRef={innerRef}
      initialValues={initialValues}
      onSubmit={(values, formik) => {
        onSubmit(values, formik);
      }}
      {...props}
    >
      {(formik) => (
        <>
          <Prompt when={formik.dirty && !formik.isSubmitting && enablePrompt} />
          <FormikForm>
            <Flex flexDir="column" gap={gap} maxWidth={maxWidth}>
              {typeof children === 'function' ? children(formik) : children}
            </Flex>
            {buttonSet}
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

Form.Group = function FormGroup({ children, ...props }) {
  return (
    <Flex flexDir="column" {...props}>
      {children}
    </Flex>
  );
};

Form.GroupHeading = function GroupHeading({ children, action }) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text mb="1" as="h4" fontSize="4">
        {children}
      </Text>
      {action}
    </Flex>
  );
};

Form.GroupParagraph = function GroupParagraph({ children }) {
  return (
    <Text mb="2" fontSize="6" color="gray.600">
      {children}
    </Text>
  );
};

Form.TwoColumn = function TwoColumn({ children }) {
  return (
    <Flex
      gap="2"
      flex="1"
      alignItems="flex-start"
      width="100%"
      flexDir={['column', 'column', 'row']}
    >
      {children}
    </Flex>
  );
};

Form.ArrayTwoColumn = function ArrayTwoColumn({
  children,
  alignItems = 'flex-start',
}) {
  return (
    <Flex gap="2" flex="1" alignItems={alignItems} width="100%">
      {children}
    </Flex>
  );
};

Form.ArrayAddButton = function ArrayAddButton({
  buttonText,
  addAction,
  buttonProps,
}) {
  return (
    <Button
      variant="outline"
      onClick={addAction}
      leftIcon={<PlusIcon boxSize={4} />}
      {...buttonProps}
    >
      {buttonText}
    </Button>
  );
};

Form.ArrayDeleteIconButton = function ArrayDeleteIconButton({
  removeAction,
  index,
  mt = 0,
  ...props
}) {
  const calculatedMT = index === 0 && mt === 0 ? 4 : mt;
  return (
    <IconButton
      aria-label="remove"
      name="delete"
      mt={calculatedMT}
      onClick={removeAction}
      variant="ghost"
      {...props}
    />
  );
};

Form.ButtonGroup = function ButtonGroup({ children, buttonGroupProps }) {
  return (
    <ChakraButtonGroup mt="2" mb="3" {...buttonGroupProps}>
      {children}
    </ChakraButtonGroup>
  );
};

function FormikField({
  component: Component,
  isRequired,
  label,
  hoverText,
  rightChildren = null,
  labelProps,
  mb = '2',
  helpText,
  ...props
}) {
  return (
    <Field {...props}>
      {({ field, meta }) => {
        const metaErrorPath = props.errorPath
          ? meta.error?.[props.errorPath]
          : meta?.error?.value || meta?.error;
        const isSwitchComponent =
          Component === ChakraSwitch || Component === ChakraCheckbox;
        const isChecked = isSwitchComponent ? { isChecked: field.value } : {};
        return (
          <FormControl
            isReadOnly={props?.isReadOnly}
            isInvalid={meta.error && meta.touched}
            mb={mb}
          >
            {label && (
              <Flex direction="row" justifyContent="space-between">
                <Flex direction="row" alignItems="center" width="100%">
                  <Flex width="100%">
                    <FormLabel
                      variant={props.labelVariant || 'primary'}
                      color={'grey.700'}
                      {...labelProps}
                    >
                      {label}
                    </FormLabel>
                    {hoverText && (
                      <TooltipWrapper
                        placement="top"
                        text={hoverText}
                        targetId={`${props.name}-tooltip`}
                      >
                        <Icon
                          name="helpOutline"
                          id={`${props.name}-tooltip`}
                          aria-label={`${props.name}-tooltip`}
                          ml={1}
                          mb="1px"
                        />
                      </TooltipWrapper>
                    )}
                  </Flex>
                  {rightChildren && rightChildren}
                </Flex>
                {isRequired && (
                  <Text
                    as="span"
                    float="right"
                    alignSelf="end"
                    mb={1}
                    fontSize="12px"
                    color="grey.600"
                  >
                    Required
                  </Text>
                )}
              </Flex>
            )}
            {props.isLoading ? (
              <Skeleton height={'40px'} />
            ) : (
              <Component {...field} {...isChecked} {...props} data-1p-ignore />
            )}
            {meta.error && meta.touched ? (
              <FormErrorMessage>{metaErrorPath}</FormErrorMessage>
            ) : (
              <Flex justifyContent="space-between">
                {helpText && <FormHelperText>{helpText}</FormHelperText>}
                {props.maxLength && (
                  <Text mt={1} mb={0} size={7} color="grey.500">
                    {`${field.value?.length}/${props.maxLength}`}
                  </Text>
                )}
              </Flex>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

Form.Switch = function FormSwitch({
  label,
  isRequired,
  hoverText,
  helpText,
  mb = '2',
  labelProps = {},
  rightChildren,
  leftChildren,
  ...props
}) {
  return (
    <Field {...props}>
      {({ field, meta }) => {
        const metaErrorPath = props.errorPath
          ? meta.error?.[props.errorPath]
          : meta?.error?.value || meta?.error;

        return (
          <FormControl
            isReadOnly={props?.isReadOnly}
            isInvalid={meta.error && meta.touched}
            mb={mb}
          >
            <Flex direction="column">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" flex="1">
                  {leftChildren}
                  <ChakraSwitch
                    {...field}
                    isChecked={field.value}
                    {...props}
                    data-1p-ignore
                    mr="2"
                    mb="0"
                  />
                  <Flex alignItems="center" flex="1">
                    <FormLabel
                      variant={props.labelVariant || 'primary'}
                      color="gray.700"
                      mb="0"
                      cursor="pointer"
                      onClick={() =>
                        field.onChange({
                          target: {
                            name: field.name,
                            value: !field.value,
                          },
                        })
                      }
                      {...labelProps}
                    >
                      {label}
                    </FormLabel>
                    {hoverText && (
                      <TooltipWrapper
                        placement="top"
                        text={hoverText}
                        targetId={`${props.name}-tooltip`}
                      >
                        <Icon
                          name="helpOutline"
                          id={`${props.name}-tooltip`}
                          aria-label={`${props.name}-tooltip`}
                          ml="1"
                          mb="1px"
                        />
                      </TooltipWrapper>
                    )}
                  </Flex>
                  {rightChildren}
                </Flex>
                {isRequired && (
                  <Text as="span" fontSize="12px" color="grey.600" ml={3}>
                    Required
                  </Text>
                )}
              </Flex>
              {meta.error && meta.touched ? (
                <FormErrorMessage>{metaErrorPath}</FormErrorMessage>
              ) : (
                helpText && (
                  <FormHelperText mt={1} mb={0}>
                    {helpText}
                  </FormHelperText>
                )
              )}
            </Flex>
          </FormControl>
        );
      }}
    </Field>
  );
};

Form.Checkbox = function Checkbox(props) {
  return <FormikField component={ChakraCheckbox} {...props} />;
};

Form.FieldArray = function FieldArray({
  name,
  title,
  children,
  ml = 2,
  labelProps,
  ...props
}) {
  return (
    <Box>
      <FormLabel color={'grey.700'} {...labelProps}>
        {title}
      </FormLabel>
      <Box ml={ml} {...props}>
        <FormikFieldArray name={name}>{children}</FormikFieldArray>
      </Box>
    </Box>
  );
};

Form.Input = function Input(props) {
  return <FormikField component={ChakraInput} {...props} />;
};

Form.DateTimeInputWithUTC = function DateTimeInputWithUTC({
  dateName,
  timeName,
  dateLabel,
  timeLabel,
  withPopover = true,
  ...props
}) {
  const { values } = useFormikContext();
  const date = get(values, dateName);
  const time = get(values, timeName);

  return (
    <Flex direction="column">
      <Form.TwoColumn mb={0}>
        <Form.Input
          name={dateName}
          label={dateLabel}
          type="date"
          isRequired
          mb={0}
        />
        <Form.Input
          name={timeName}
          label={timeLabel}
          type="time"
          isRequired
          mb={0}
        />
      </Form.TwoColumn>
      {date && time && (
        <DateTime
          withPopover={withPopover}
          format="legacy"
          timestamp={moment(`${date}T${time}:00`).format()}
        />
      )}
    </Flex>
  );
};

Form.Textarea = function Textarea(props) {
  return (
    <FormikField component={TextArea} {...props}>
      {props.markdownEnabled && <TextAreaFooterMarkdownLink />}
    </FormikField>
  );
};

Form.Dropdown = function Dropdown({
  type = 'select',
  asyncUrl,
  customLoadOptions = null,
  arrayItemIndex = null,
  labelFormatType,
  groupOptions = null,
  internal = false,
  customOptionConversion = null,
  fitContent = false,
  labelProps = {},
  ...props
}) {
  const formik = useFormikContext();
  const Component = useMemo(() => getDropdownComponent(type), [type]);

  const params = {};

  if (type === 'async-paginate') {
    params.loadOptions = loadAsyncPaginateOptions(
      asyncUrl,
      null,
      'query',
      internal,
      labelFormatType,
    );
  }

  if (type === 'async' || (type === 'creatable' && !customLoadOptions)) {
    params.loadOptions = loadAsyncOptions(
      asyncUrl,
      labelFormatType,
      true,
      'query',
      groupOptions,
      internal,
      customOptionConversion,
    );

    params.defaultOptions = true;
  }

  if (customLoadOptions) {
    params.loadOptions = customLoadOptions;
  }

  return (
    <FormikField
      labelProps={{ ...labelProps, htmlFor: props.name }}
      component={Component}
      fitContent={fitContent}
      onChange={async (value) => {
        await formik.setFieldValue(props.name, value);
        await formik.setFieldTouched(props.name, true);
      }}
      inputId={props.name}
      {...props}
      label={!arrayItemIndex ? props.label : false}
      labelVariant={arrayItemIndex !== null && 'array'}
      {...params}
    />
  );
};

Form.ArrayButton = function ArrayButton({ children, ...props }) {
  // TODO: better way to offset margin
  return (
    <Button mt="23.8px" {...props}>
      {children}
    </Button>
  );
};

Form.propTypes = {
  validationSchema: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  maxWidth: PropTypes.string,
  buttonSet: PropTypes.element,
  innerRef: PropTypes.object,
  enablePrompt: PropTypes.bool,
};

export default Form;
